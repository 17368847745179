import './LeftBar.css';
import '../Root.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default function PanelPlus() {
    return (
        <>
            <div className='navbar-panel'>
            <p>Plus</p>
        </div>
        </>
    );
}